import { Box, Typography, Grid } from "@mui/material";
import TraitPopup from "./EmbedCard/TraitPopup";
const sx = {
  cardItem: {
    height: "100%",
    width: "100%",
  },
  nftContainer: {
    backgroundColor: "transparent",
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    // borderRadius: "20px",
    overflow: "hidden",
    transition: "all .3s",
    perspective: "1px",

    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)",
      borderColor: "transparent",
      "& button": {
        display: "flex",
      },
    },
  },
  nftImg: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

const Card = ({ setFullscreenSource, metadata, showRedeem }) => {
  return (
    <Grid item xs={12} sm={6} md={4} sx={sx.cardItem}>
      <Box sx={sx.nftContainer}>
        <Box
          component="img"
          sx={sx.nftImg}
          src={metadata.image}
          onClick={setFullscreenSource}
        />
        {!metadata.redeemed && (
          <TraitPopup showRedeem={() => showRedeem(metadata.tokenId)} />
        )}
      </Box>
      <Typography
        variant="text"
        component="p"
        sx={{ mt: 3, textAlign: "center", fontWeight: 700 }}
      >
        ID: #{metadata.tokenId}
      </Typography>
    </Grid>
  );
};

export default Card;

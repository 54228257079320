import leftTrick from "../assets/images/leftTrick.svg";
import rightTrick from "../assets/images/rightTrick.svg";
import { Box } from "@mui/system";

const SectionDividers = () => (
  <Box
    style={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "15px",
    }}
  >
    <img src={leftTrick} style={{ maxHeight: "50px" }} />
    <img src={rightTrick} style={{ maxHeight: "50px" }} />
  </Box>
);

export default SectionDividers;

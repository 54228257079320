import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import eths from "../assets/images/eth-icon.svg";

const innerSx = {
  root: {
    minHeight: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0 8px",
  },
  value: {
    color: "#1C1E23",
    fontSize: "26px",
    lineHeight: "28px",
    fontFamily: "poppins-medium",
  },
  label: {
    color: "##000407",
    fontSize: "12px",
    fontFamily: "poppins",
    lineHeight: "20px",
    mt: "5px",
  },
};

const InfoBox = ({ value, label, eth, style, sx }) => (
  <Box sx={{ ...innerSx.root, ...sx }} style={{ ...style }}>
    <Box>
      <Box sx={innerSx.content}>
        {eth ? <img src={eths} style={{ width: "15px" }} alt="eth" /> : ""}

        <Typography sx={innerSx.value}>{value}</Typography>
      </Box>

      <Typography sx={innerSx.label}>{label}</Typography>
    </Box>
  </Box>
);

InfoBox.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  eth: PropTypes.any,
};

export default InfoBox;

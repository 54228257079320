import React, { useState, useEffect } from "react";
import useWeb3Ctx from "../../../hooks/useWeb3Ctx";
import {
  useZoom2Contract,
  useGeneticatContract,
} from "../../../hooks/useContract";
import { zoomFetchTokenUris } from "../../../utils/zoom2";
import Card from "../../../components/Card";
import FullscreenModal from "../../../components/FullscreenModal";
import SectionDividers from "../../../components/SectionDividers";

import { SpinnerCircularFixed as Spinner } from "spinners-react";
import { Box, Typography, Button, Grid } from "@mui/material";

const sx = {
  root: {
    position: "relative",
    minHeight: "30vh",
    width: "100%",
    height: "100%",
    background: "#fff",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
    mb: 4,
  },
  title: {
    marginTop: "8px",
    textAlign: "center",
    fontFamily: "poppins-medium",
    fontSize: "36px",
  },
  connectBtn: {
    minWidth: "150px",
    margin: "auto",
  },
};

const Wallet = ({ showRedeem, redeemedId, setRedeemedId }) => {
  const { handleConnect, address } = useWeb3Ctx();
  const [loading, setLoading] = useState(false);
  const [fullscreenSrc, setFullscreenSrc] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const [metadata, setMetadata] = useState([]);

  const geneticatContract = useGeneticatContract();
  const zoomContract = useZoom2Contract();

  useEffect(() => {
    if (address) {
      getTokens();
    }
  }, [address]);

  useEffect(() => {
    if (!isOpen) {
      setFullscreenSrc(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (redeemedId) {
      if (metadata.length > 0) {
        const newMetadata = metadata.map((token) => {
          if (token.tokenId == redeemedId) {
            token.redeemed = true;
          }
          return token;
        });
        setMetadata(newMetadata);
        if (setRedeemedId) {
          setRedeemedId(null);
        }
      }
    }
  }, [redeemedId]);

  const getTokens = async () => {
    setLoading(true);

    const tokens = await zoomFetchTokenUris(
      geneticatContract,
      zoomContract,
      address
    );
    if (tokens) {
      setMetadata(tokens);
    } else {
      setMetadata([]);
    }
    setLoading(false);
  };

  const setFullscreen = (e) => {
    console.log(e);
    setOpen(true);
    setFullscreenSrc(e.target.src);
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.content}>
        <SectionDividers />
        <Typography sx={sx.title} variant="heading">
          Collection Wallet
        </Typography>
        <Typography variant="text" component="p" sx={{ my: 4 }}>
          Here you can view all your NFTs from this collection
        </Typography>
        {!address && (
          <Box sx={{ boxSizing: "border-box" }}>
            <Typography variant="text" component="p" sx={{ mb: 4 }}>
              In order to see your tokens, you need to connect your wallet
            </Typography>
            <Button
              variant="outlined"
              sx={sx.connectBtn}
              onClick={handleConnect}
            >
              Connect Wallet
            </Button>
          </Box>
        )}

        {loading && (
          <Box sx={{ textAlign: "center" }}>
            <Spinner color="#681EAA" />
          </Box>
        )}

        {address && !loading && (
          <>
            {metadata.length > 0 ? (
              <Grid container spacing={5} sx={{ my: 3 }}>
                {metadata.map((metadata) => {
                  return (
                    <Card
                      key={metadata.tokenId}
                      setFullscreenSource={setFullscreen}
                      metadata={metadata}
                      showRedeem={showRedeem}
                    />
                  );
                })}
              </Grid>
            ) : (
              <Typography variant="text">Your wallet is empty</Typography>
            )}
          </>
        )}
      </Box>

      <FullscreenModal
        setOpen={setOpen}
        isOpen={isOpen}
        imgSrc={fullscreenSrc}
      />
    </Box>
  );
};

export default Wallet;

import { Box } from "@mui/material";

import bottomLeft from "../assets/images/leftBottomTrick.svg";
import bottomRight from "../assets/images/rightBottomTrick.svg";

const BottomBorders = () => {
  return (
    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
      <img src={bottomLeft} style={{ maxHeight: "50px" }} />
      <img src={bottomRight} style={{ maxHeight: "50px" }} />
    </Box>
  );
};
export default BottomBorders;

import React, { forwardRef, useState, useEffect, useRef } from "react";
import useContainerDimensions from "../../hooks/useContainerDimensions";
import iconVideo from "../../assets/images/icon-video.svg";
import "./Media.css";

import { SpinnerCircularFixed as Spinner } from "spinners-react";

const Image = forwardRef(
  (
    { mediaType, mediaUrl, style, autoPlay, traitPopup, customWidth },
    forwardedRef
  ) => {
    {
      const containerRef = useRef();
      const [playVideo, setPlayVideo] = useState(false);
      const [playError, setPlayError] = useState(false);
      const [loadingVideo, setLoadingVideo] = useState(false);
      const { width, height } = useContainerDimensions(containerRef);

      const [containerWidth, setContainerWidth] = useState(null);
      const childContainer = useRef();
      const imageRef = useRef();
      const videoRef = useRef(null);
      const nftWidth = customWidth || 1.365;
      const childContainerStyle = {
        width: width < height / nftWidth ? 100 + "%" : height / nftWidth,
        marginTop:
          width < height / nftWidth ? (height - width * nftWidth) / 2 : 0,
        marginLeft: "auto",
        marginRight: "auto",
      };

      useEffect(() => {
        setContainerWidth(width);
      }, [width]);

      return (
        <div ref={containerRef} className={`parent-container`}>
          {containerWidth > 0 && (
            <div ref={childContainer} style={childContainerStyle}>
              <div
                ref={forwardedRef}
                className={"nft-container"}
                style={{ ...style, paddingTop: nftWidth * 100 + "%" }}
              >
                {traitPopup}

                {mediaType === "image" ? (
                  <img src={mediaUrl} alt="" />
                ) : (
                  <div>
                    {!autoPlay && (
                      <div
                        className="play-btn-container"
                        onMouseEnter={() => {
                          !autoPlay && setPlayVideo(true);
                        }}
                        onMouseLeave={() => {
                          !autoPlay && setPlayVideo(false);
                        }}
                      >
                        <div
                          style={{
                            visibility: playVideo ? "hidden" : "visible",
                          }}
                        >
                          <img src={mediaUrl} alt="" />
                          <img className="play-btn" src={iconVideo} alt="" />
                        </div>
                      </div>
                    )}

                    <video
                      style={{ visibility: playVideo ? "visible" : "hidden" }}
                      ref={videoRef}
                      loop
                      preload={"none"}
                      muted
                      autoPlay={autoPlay}
                      playsInline
                      onLoadStart={() => {
                        setLoadingVideo(true);
                      }}
                      onCanPlayThrough={() => {
                        setLoadingVideo(false);
                      }}
                      onError={() => {
                        setPlayError(true);
                      }}
                      src={mediaUrl}
                    />

                    {playVideo && loadingVideo && (
                      <div className="spinner-container">
                        <Spinner></Spinner>
                      </div>
                    )}
                  </div>
                )}

                {/* {displayFullScreen && (
                  <div className="fullscreen-btn-container">
                    <div
                      className="fullscreen-btn btn"
                      onClick={() => {
                        requestFullScreen();
                      }}
                    >
                      <img src={fullscreenIcon} alt="" />
                    </div>
                  </div>
                )} */}
              </div>
              {/* <div className="token-shadow">
                <img src={shadow}></img>
              </div> */}
            </div>
          )}
        </div>
      );
    }
  }
);

export default Image;

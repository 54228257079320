import { useState } from "react";
import { Typography, Box, TextField, Button } from "@mui/material";
import PopupModal from "../../../components/PopupModal";
import { SpinnerCircularFixed as Spinner } from "spinners-react";

const sx = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    fontFamily: "poppins",
    fontWeight: 700,
    size: 26,
    mb: 1,
  },
  text: {
    fontFamily: "poppins",
    fontSize: 12,
  },
  emailField: {
    my: 3,
    input: { color: "#000" },
  },
};

const RedeemModal = ({ setOpen, isOpen, redeemKyc, loading }) => {
  const tokenId = isOpen;
  const [isValid, setIsValid] = useState(null);
  const [email, setEmail] = useState("");

  const isEmail = (emailAddress) => {
    const emailRegex = /\S+@\S+\.\S+/;

    return emailRegex.test(emailAddress);
  };

  const handleChange = (event) => {
    const val = event.target.value;
    validateEmail(val);
    setEmail(val);
  };

  const validateEmail = (email) => {
    if (isEmail(email)) {
      setIsValid(null);
    } else {
      setIsValid("Invalid Email");
      return false;
    }

    return true;
  };

  const submit = () => {
    if (validateEmail(email)) {
      redeemKyc(email, tokenId);
    }
  };

  return (
    <PopupModal setOpen={setOpen} isOpen={tokenId != null} header={"Redeem"}>
      <Box sx={sx.content}>
        <Typography sx={sx.title}>
          Redeem your 30x Whole Genome Sequencing (DNA) Kit trait.
        </Typography>
        <Typography sx={sx.text}>
          Enter your email address below and sign with your wallet. <br></br>You will
          receive an email within 5-7 working days with a 100% discount code to
          be used at <a href="https://genomes.io" target="_blank">genomes.io</a>. This code will entitle you to 1 x Kit and 1 x
          Lifetime Subscription only.
        </Typography>

        <TextField
          sx={sx.emailField}
          error={isValid != null}
          id="filled-error-helper-text"
          label="Email"
          helperText={isValid}
          variant="filled"
          onBlur={handleChange}
        />

        <Button disabled={isValid != null} onClick={submit}>
          {loading ? (
            <Spinner
              color="#fff"
              style={{ width: "24px", marginLeft: "auto", marginRight: "auto" }}
            />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </PopupModal>
  );
};

export default RedeemModal;

import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ConnectWallet from "./ConnectWallet";
import { Box, Collapse, Button } from "@mui/material";
import genomes from "../assets/images/genomes logo.svg";
import menuIcon from "../assets/images/menu_icon.svg";
import collapseIcon from "../assets/images/collapse_icon.svg";

const BP1 = "@media (max-width: 570px)";
const BP2 = "@media (max-width: 444px)";

const sx = {
  root: {
    width: "100%",
    backgroundColor: "#FFFF",
    position: "fixed",
    zIndex: 1001,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    px: "20px",
    width: "100%",
    maxWidth: "1233px",
    height: "100%",
    mx: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    [BP1]: {
      display: "none",
    },
  },
  logo: {
    diplay: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#681EAA",
    color: "#FAFAFA",
    fontSize: "14px",
    fontFamily: "poppins-semibold",
    padding: "14px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#681EAA",
    },
  },
  logoContainer: {
    position: "absolute",
    width: "100%",
    top: "16px",
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
  },
  mobileContainer: {
    zIndex: "1",
    display: "none",
    [BP1]: {
      position: "absolute",
      top: "10px",
      display: "block",
      gap: "30px",
      width: "100%",
    },
  },
  headerRoot: {
    top: 0,
    height: "60px",
    width: "100%",
    zIndex: "1",
    maxWidth: "1272px",
    mx: "auto",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  menubtn: {
    pr: "20px",
    zIndex: "1",
    marginLeft: "15px",
    minWidth: "70px",
    height: "70px",
    backgroundColor: "transparent",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  fixedMenu: {
    height: "60px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  collapsedMenu: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    gap: "5px 0",
    paddingBottom: "25px",
    alignItems: "center",
    boxShadow: "0 4px 6px 0 rgba(0,0,0,0.25)",
  },
};
const Header = () => {
  const rootRef = useRef(null);
  const [isFixed, setFixed] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        setFixed(window.scrollY >= 80);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
    return () => {};
  }, []);

  return (
    <Box
      sx={{
        ...sx.root,
        boxShadow: isFixed ? "0 4px 6px 0 rgba(0,0,0,0.25)" : "unset",
        minHeight: isFixed ? "80px" : "124px",
        transition: isFixed ? "all .3s ease-in-out" : "all .1s ease-in-out",
        [BP1]: {
          minHeight: "80px",
        },
      }}
      ref={rootRef}
    >
      <Box sx={sx.content}>
        <a href="https://nft.genomes.io/" target="_blank">
          <img src={genomes} style={{ maxWidth: "206px" }} />
        </a>

        <ConnectWallet />
      </Box>
      <Box sx={sx.mobileContainer}>
        <Box
          sx={sx.headerRoot}
          onClick={() => setMenuOpened((state) => !state)}
        >
          <Box sx={sx.fixedMenu}>
            <Button sx={sx.menubtn}>
              <img
                style={{ width: "100%" }}
                src={menuOpened ? collapseIcon : menuIcon}
                alt="Menu icon"
              />
            </Button>
          </Box>
          <Box sx={sx.logoContainer}>
            <img src={genomes} style={{ width: "144px" }} alt="logo" />
          </Box>
        </Box>

        <Collapse in={menuOpened} timeout={100} unmountOnExit>
          <Box sx={sx.collapsedMenu}>
            <ConnectWallet />
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
export default Header;

const sigsTest = {
    "0xaA8fbD819dB3310d87bBa49df5CC90e157100570": [
        "0x7ba398ceddec1371c75dd0e7b762934a28dd143075627b6e0a76739b27841efd2ee11f30d457b0c68e544a15f09f669efa18acfa4f131b6574e0c3a22b63b6c81c"
    ],
    "0x40F7AfE9Fa13f3F2fE609e61ff1C7EB4410EB5b6": [
        "0x2205e9f0aecd4b2cce04c688cb71f18d6077d9241be231a9c5db7baf4e12d6746ad655a3d5f9761e80802d1288adea61b7f4aec3e6665d3ce5575cf73f761eee1b"
    ],
    "0xdFcD36c7F2DF99002da4BeF1b188224ADB151c0a": [
        "0x4a2a2eca236d58f3ffe01b3a068e0dc002ecd8b4c836ed05fb246a47f231dd6c31e5822512d84174bf40b2a3bb8aa83c3020f14233142f08192b535231d22f341b"
    ],
    "0x0A2926f2E2C9d60AEBf5Cfe0911FbdeFCE47Db5E": [
        "0xeb5f77d3e60ca05f054506d2ed3ff7a8bfcf48f1f5209d24c36cde00eb1ca9577fd6d1323e9b5fb8fd809ad6f2432dbccc719be77b9fdeb853e0617476ec03471c"
    ],
    "0xaC52f35ACa0D1F48e0127f42bBd3C767F1af6bA0": [
        "0x73b37d4c418f026dc73abf2239dcd9c12c56bd7ac301762c536be1c0ebae88880ad12a4415ceedcc7481d54280bbb76b3e807e2ab0be6008fa61ad3419dc67ae1b"
    ],
    "0xEC1a9407B39696c0c85CDc0CdfE36fa2E7063617": [
        "0x09eb01d329f009c81d992a78f3aec596d403aa1636ea350574fbfed8a4b76d6956e518d983fa7816f32aa1701c5579cc2e2f8620acb3db837700b549c1c1ec5b1c"
    ],
    "0x9c69Dcd15FB57AA0DB5580a783e7AF58a8Cedc7D": [
        "0x2b776a90079864c13f29d56140d8e985a1ba09bbf0eebb29cfe9e4a6374af1e66b8092283039f2c5204fdf004a2272593b5b243a6115a03837c9e1188daf6c761b"
    ],
    "0x1D2E5B42487f5C5A2c4970B80d3C5AaD1B6ec8D0": [
        "0x185a1bb5a33c1e436ccc68e95136f14d06cb4f30679913efe449ff6d33d0f98a042c9540ce700d128dd980abc8cdea68aab2c4909a98161684671b55ce9ed2a31b"
    ],
    "0x02d1D2b995c1b066c5692969f877Cd7d8C65B1C6": [
        "0x7e5711248067dbbf9026d91e72f4486a224e7ae919ddef055f8fcf16ef05d2284293436990ee6768bb038700331dce76af6364e9e2f7d91978bbf6f70b88652a1c"
    ],
    "0xd65A38bE8f6358794802cF62cb5a408F9b1ce596": [
        "0x82c8574b1df745bedc8f466098b7f7e85ed025039feaa78a33b89be7271791d8777da0c2fa98f3394cd234b00ad0bdfd5622817c5a029103d95bbbaabf9844231c"
    ],
    "0x92Dd37eD2f239A60477Ab2Fc25939EFd9b967acA": [
        "0xc902eda49a771a8072e94fae736a77ffd0155e18d896b4ffbd32c5ebf9bfd12010016e43deb3c6eb34ee884c561ddfd24630cbb602d161413ee8b2a362e5aa921c"
    ],
    "0xcC6F94B497A357a534Af186f360699b10687EFbb": [
        "0x7b9e74525468a2e88bc148142382901e6ccc809a3e8445e4d75c6786cc0e44d209b536d9183e217181367dffd69a98d7892fbb40469675b1bb5b286e492216091b"
    ],
    "0x676A27626646a5ab57F0da93A3c5147E7cA16d4B": [
        "0x5ec44fa70fff328fbb8dbdb7b11b69fc099404f9ecd7bf337091483b3c2cfbbd73c406f917a0769f756d0fffd0f3d6ceb08f2f9da5ef8145c8b89d9fe7061b661b"
    ],
    "0x65B399c4DD86C4de6c79dC701390D83dC395a159": [
        "0xc03ed2cc4d7e49373092a3f30c399156a7c2b15e8403700fe1cad5dc1bbf69190845a2020bc974a2538ca56ac8184414c4aa34164021231d65886309da24bdab1c"
    ],
    "0x04Ba43B4Bdb4236F1800a7d5A2d29a95014cF62d": [
        "0xaaafc3658b017fc7446ea397fb9300b59014cb30a15f2d01d046a175915ac04b2294a79a61c20e724634ef2876e151460a1eef37e94bd3fa6f20c6b6708bf48f1c"
    ],
    "0x85200762427c6C67cBfb1141D4084DaDe0045BFc": [
        "0xf3f6754e10161fd257430abb7ca081237710a4ef38636db6ccdb7e955354a4ab19520c074773f70e8c00a2543e164bedb55fac66309a3f9b9189717ebb6210f21b"
    ],
    "0x3d94c5bfD125F566AcD91DCefC637967639e23ee": [
        "0xa13c140227f088ac7cb3aeda0557339dbb7100b37f565a366a63184d5210344515948ca47384a47933bcbf132272fe64e1c63947677e1c1616da9aa8e7ccf0451c"
    ],
    "0xeA3F46C50AE211Fe59107321A32aF97F215df566": [
        "0x3cb52a4742d8d8f648925dde9f46e1e1fe29149fab8133b4561fd6ac4c7bf68a7f953b245d158ac0b1b39592b4fd443a534dff8697e7e00839239745197017601c"
    ],
    "0x3BFAd899CAd4fdf1cbA500F5fD94BA4737040f1e": [
        "0x7adf1f08f58d1da94ef30d8b03602912aa8b692c2d054e4e563ca05380b9ad33446a9485d14312dd81a012f6fd8ba42a6aa4ff875eacec5b24f1298515134f181b"
    ],
    "0xa4Cc712177246974aeae43CBfd1FBf3a76C528fe": [
        "0x1ba424f29fa1c137516f33ac21332a97779c0bb04aa3fffb63bf032169b98b8167d2c705666b6b95a78afb843096ba5e3d52840ebed7a1e89d9cbda32b8171501c"
    ],
    "0xe1Ae0d1721A61B7BE6Cb8fE2BC046dC940C4Ab31": [
        "0x0e7664c086531d9ea5ba8d71d7dd61654cd3b01b1740e6bda30b715fe4efad9f2433cc5ea728297126e61a42083ae094d15706a133a51ade03c8353e526f61ef1b"
    ],
    "0xfe7EbC878B7106EF813e32452Ba14Fe99851e311": [
        "0x336617d0b0a889838113acf0dc511b65234269947011b06a1a3af14c3e4e5ff434befe042bf17c0501ea4ffc1bb8ff3527860c06b5595c040d739492369a3da01c"
    ],
    "0xe51E57fE4a952B75f0b1Fa2fDfDd0BAE94e30Ff6": [
        "0xdddd0058df6a6580e84990fcfc2b464e5c00e5ec0de59da2dcc6f1ce0d915e396b4cb06f7f9904aa3925a96424f92248881c58b17bc7b7d60430d93fcf59a9df1c"
    ],
    "0x7dF19D329EE9a1595e0CDc5C3f38dAB5012cA304": [
        "0xf1e0f84dedf4da206bf3f2f198842fa5fec3961a140a4ca0d5bf887e52c949eb5c25ec41dd44bf5b84dbbb1b969155098c13682e9ac58ca60aea42cfef959c6d1c"
    ],
    "0x85F3a95563dAF7331F6b36E75FD209DA64abdF33": [
        "0x1bcc775bf5170147f96834127027cb184e4e14a9ba8dd0881d75ff6c852cd8f817261c56d1fb0413313fc146af67ee5ecbe5a648c6bf12b4ec7d112b2d2985041b"
    ],
    "0xe4e2aF81822C983cd2761827C6F97805ba50d209": [
        "0xa0fbce51f86e320463483518ad9b040cfd33dc4f3724e893ff9d666218bb09a975169aced893c9ea63a6c924a60e8f2bb922ce22d5194c3107c20e958542ecb01b"
    ],
    "0x6FCCA8A59b545754E8f66bA29cCf2AE7f1138490": [
        "0x45bd6ce96cfd47eec8f8e69e50d5b3823a1a95a82debaf571ae0480608152bd139bc4dbb30c839be810ce6441caac0a0e1b69be493c70575e286e2144d746c261c"
    ],
    "0xc9B958E0f7E8ABe45a99CCCBe06c31Dc46D8C995": [
        "0xf72694d3c65a96eca6550808aaea8fd6690b19c378ccb8a2195cbcfa1fd8b02e7f832f77222ad4854f41a12ee372e9280c3193e16b3826eb8b82d2d42feaabc61b"
    ],
    "0xd2F7e3cDEFF47922b2Ba5EaBA27B2d55E7B3f7A7": [
        "0xc2df372391a27fa0c8e496ccaa3c59a1b10207ae000f8df36be7d1e6c49ece563023446ac84de88c97b9c17eed2b74b5898701876a282df5c9e6aa05d430e0441b"
    ]
}
export default sigsTest;
import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button, ButtonGroup } from "@mui/material";
import { SpinnerCircularFixed as Spinner } from "spinners-react";

import {
  useGeneticatContract,
  useEcContract,
  useZoom2Contract,
} from "../../hooks/useContract";
import useInterval from "../../hooks/useInterval";
import useWeb3Ctx from "../../hooks/useWeb3Ctx";
import useRedeemKyc from "../../hooks/useRedeemKyc";
import { getTokenIds } from "../../utils/zoom2";
import sigs from "../../sigs";
import sigsTest from "../../sigs-test";

import { ethers } from "ethers";
import { toast } from "react-toast";
import config from "../../config";

// import UtilityCard from './components/UtilityCard';
import SocialButton from "../../components/SocialButton";
import Header from "../../components/Header";
import TopBorders from "../../components/TopBorders";
import BottomBorders from "../../components/BottomBorders";
import banner from "../../assets/images/banner.jpg";
import smallBanner from "../../assets/images/small-banner.jpg";
import physical from "../../assets/images/physical.svg";
import InfoBox from "../../components/InfoBox";
import Timer from "../../components/Timer";
import Counter from "../../components/Counter/Counter";
import UtilityCard from "../../components/UtilityCard";
import SectionDividers from "../../components/SectionDividers";
import PermissionErrorModal from "./components/PermissionErrorModal";
import CheckoutModal from "./components/CheckoutModal";
import BackdropModal from "./components/BackdropModal";
import TxProgressModal from "./components/TxProgressModal";
import RedeemModal from "./components/RedeemModal";
import Wallet from "./components/Wallet";
import galaxis from "../../assets/images/galaxis.svg";

const ERROR_DOWNLOAD_STATS = "ERROR_DOWNLOAD_STATS";

const BP2 = "@media (max-width: 1345px)";
const BP4 = "@media (max-width: 600px)";
const BP5 = "@media (max-width: 899px)";
const BP6 = "@media (max-width: 700px)";
const BP3 = "@media (max-width: 384px)";
const BP7 = "@media (max-width: 820px)";
const BP1 = "@media (max-width: 570px)";

const sx = {
  root: {
    width: "100%",
    pt: "125px",
    position: "relative",
    backgroundColor: "background.main",
    boxSizing: "border-box",
    [BP1]: {
      pt: "80px",
    },
  },
  container: {
    margin: "auto",
    mt: "-5px",
    position: "relative",
    pt: "50px",
    pb: "100px",
    maxWidth: "1233px",
    overflow: "hidden",
    transition: "all .3s",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    px: "20px",
    [BP4]: {
      pt: "39px",
    },
  },
  nameContainer: {
    marginTop: "50px",
    position: "absolute",
    mx: "auto",
    display: "flex",
    maxWidth: "1140px",
    width: "100%",
    zIndex: "1",
    backgroundColor: "#511BA3",
    [BP4]: {
      marginTop: "35px",
    },
  },
  name: {
    position: "absolute",
    height: "135px",
    width: "135px",
    backgroundColor: "#511BA3",
    borderRadius: "15px",
    bottom: "4px",
    left: "0",
    right: "0",
    marginRight: "auto",
    marginLeft: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  badgeContainer: {
    position: "relative",
    margin: "auto",
    // maxWidth: "300px",
    borderRadius: "12px",
    border: "solid 1px #707070",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "12px",
    px: "20px",
  },
  badgeLeft: {
    borderRight: "solid 1px #707070",
    paddingRight: "15px",
    [BP3]: {
      borderRight: "unset",
      borderBottom: "solid 1px #707070",
      width: "100%",
      paddingRight: "0",
    },
  },
  badgeCenter: {
    borderRight: "solid 1px #707070",
    paddingRight: "15px",
    [BP3]: {
      borderRight: "unset",
      borderBottom: "solid 1px #707070",
      width: "100%",
      paddingRight: "0",
    },
  },
  badgeRight: {
    [BP3]: {
      width: "100%",
    },
  },
  timeContainer: {
    padding: "4px",
    margin: "auto",
    mt: "20px",
    maxWidth: "400px",
    borderRadius: "12px",
    border: "solid 1px #707070",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "12px",
    [BP4]: {
      padding: "10px",
    },
  },
  cardContainer: {
    mx: "auto",
    mt: "50px",
    mb: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "40px 16px",
  },
  title: {
    fontFamily: "poppins-medium",
    fontSize: "36px",
  },
  titleLive: {
    color: "#681EAA",
  },
  socialContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "17px",
    mt: "20px",
    mb: "30px",
  },
  description: {
    fontFamily: "bau",
    color: "#030000",
    fontSize: "16px",
    lineHeight: "24px",
    maxWidth: "606px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    mt: "25px",
  },
  nftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  largeBanner: {
    display: "block",
    [BP7]: {
      display: "none",
    },
  },
  smallBanner: {
    display: "none",
    [BP7]: {
      display: "block",
    },
  },
  button: {
    backgroundColor: "#681EAA",
    color: "#FAFAFA",
    fontSize: "14px",
    fontFamily: "poppins-semibold",
    padding: "14px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#681EAA",
    },
  },

  tabContainer: {
    justifyContent: "center",
    paddingTop: "30px",
  },
  tabBtns: {
    color: "#000",
    borderColor: "#000",
    "&:hover": {
      borderColor: "#000",
      backgroundColor: "#000",
      color: "#fff",
      borderRightColor: "#000 !important",
    },
  },
  tabSelected: {
    backgroundColor: "#000",
    color: "#fff",
  },
};

const REFRESH_INTERVAL = 20000;

export const SALE_STATUS = {
  UPCOMING_PRESALE: 0,
  PRESALE: 1,
  PRESALE_ENDED: 2,
  SALE: 3,
  SALE_ENDED: 4,
};

const getShrinkStyle = (bp) => ({ [bp]: { justifyContent: "center" } });

const Home = () => {
  const { address, handleConnect } = useWeb3Ctx();
  const geneticatsContract = useGeneticatContract();
  const ecContract = useEcContract();
  const zoomContract = useZoom2Contract();

  const [refreshInterval, setRefreshInterval] = useState(REFRESH_INTERVAL);
  const [initDone, setInitDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approveInProgress, setApproveInProgress] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);

  const [presaleStart, setPresaleStart] = useState(null);
  const [saleStart, setSaleStart] = useState(null);
  const [minted, setMinted] = useState(null);
  const [tab, setTab] = useState(0);

  // Checkout Infos
  const [saleStatus, setSaleStatus] = useState(SALE_STATUS.UPCOMING_PRESALE);
  const [salePrice, setSalePrice] = useState(0.165);
  const [presalePrice, setPresalePrice] = useState(0.15);
  const [whitelistLimit, setWhitelistLimit] = useState(0);
  const [whitelistSig, setWhitelistSig] = useState(null);
  const [bestEcId, setBestEcId] = useState(null);

  const [showCheckout, setShowCheckout] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showRedeem, setShowRedeem] = useState(null);
  const [redeemedId, setRedeemedId] = useState(null);
  const { redeemKyc, loading } = useRedeemKyc();
  
  useEffect(() => {
    setApproveInProgress(loading);
  }, [loading]);

  useEffect(() => {
    if (address && ecContract && useZoom2Contract) {
      (async () => {
        const ecTokens = await ecContract.balanceOf(address);
        if (ecTokens > 0) {
          const ids = await getTokenIds(
            zoomContract,
            ecContract,
            address,
            ecTokens
          );
          const sortedIds = ids.sort((a, b) => {
            return Number(a) - Number(b);
          });
          const bestId = sortedIds[0];
          setBestEcId(bestId);
        } else {
          setBestEcId(null);
        }
      })();
    }
  }, [address, ecContract, zoomContract]);

  useEffect(() => {
    if (address) {
      let signatures;
      if (config.DEPLOYED_CHAIN_ID == 4) {
        signatures = sigsTest;
      } else {
        signatures = sigs;
      }
      const key = Object.keys(signatures).find(
        (key) => key.toLowerCase() == address.toLowerCase()
      );
      if (key) {
        console.log("Found whitelist", key);
        setWhitelistSig(signatures[key]);
      } else {
        setWhitelistSig(null);
      }
    } else setWhitelistSig(null);
  }, [address]);

  useEffect(() => {
    getInfo();
    getMinted();
  }, [geneticatsContract]);

  useEffect(() => {
    getWhitelistLimit();
  }, [geneticatsContract, address]);

  useInterval(
    () => {
      if (saleStatus == SALE_STATUS.SALE_ENDED) {
        setRefreshInterval(null);
      } else {
        getMinted();
      }
    },
    refreshInterval,
    true
  );

  const getMinted = async () => {
    if (geneticatsContract) {
      const minted = await geneticatsContract.totalSupply();
      setMinted(Number(minted));
    }
  };

  const getWhitelistLimit = async () => {
    if (geneticatsContract && address) {
      const limit = await geneticatsContract.discontMintLimit();
      const claimed = await geneticatsContract.whitelist_claimed(address);
      setWhitelistLimit(Number(limit.sub(claimed)));
    } else setWhitelistLimit(0);
  };

  const getInfo = async () => {
    if (geneticatsContract && !isLoading) {
      setIsLoading(true);
      const info = await geneticatsContract.tellEverything();
      const discountEnd = Number(info.discount_end);
      const discountStart = Number(info.discount_start);
      const saleStart = Number(info.sale_start);
      const saleEnd = Number(info.sale_end);
      // const availableSale = Number(info.__availableSales);

      // setMinted(10000 - availableSale);

      // console.log("available sale", availableSale);

      console.log(
        "discount_start ",
        new Date(discountStart * 1000).toLocaleString()
      );
      console.log(
        "discount_end   ",
        new Date(discountEnd * 1000).toLocaleString()
      );
      console.log(
        "sale_start     ",
        new Date(saleStart * 1000).toLocaleString()
      );
      console.log("sale_end       ", new Date(saleEnd * 1000).toLocaleString());

      console.log(
        "reveal_start   ",
        new Date(info.reveal_start * 1000).toLocaleString()
      );

      let now = Date.parse(new Date()) / 1000;
      const presaleStarted = Math.floor(discountStart) - now < 0;
      const presaleEnded = Math.floor(discountEnd) - now < 0;
      const saleStarted = Math.floor(saleStart) - now < 0;
      const saleEnded = Math.floor(saleEnd) - now < 0;

      setSaleStart(new Date(saleStart * 1000));
      setPresaleStart(new Date(discountStart * 1000));

      if (saleEnded) {
        setSaleStatus(SALE_STATUS.SALE_ENDED);
      } else if (saleStarted) {
        setSaleStatus(SALE_STATUS.SALE);
      } else if (presaleEnded) {
        setSaleStatus(SALE_STATUS.PRESALE_ENDED);
      } else if (presaleStarted) {
        setSaleStatus(SALE_STATUS.PRESALE);
      }
      setIsLoading(false);
      if (!initDone) {
        setInitDone(true);
      }
    }
  };

  const handleMint = () => {
    if (saleStatus == SALE_STATUS.SALE) {
      setShowCheckout(true);
    } else if (saleStatus == SALE_STATUS.PRESALE) {
      if (bestEcId || whitelistSig) {
        setShowCheckout(true);
      } else {
        setShowErrorPopup(true);
      }
    }
  };

  const mintPresale = async (quantity, totalPrice) => {
    setApproveInProgress(true);
    console.log(quantity, totalPrice);
    console.log("start whitelist mint");

    const payingAmount = ethers.utils.parseEther(totalPrice.toString());

    let sig = "0x00";
    let ecId = 0;

    if (whitelistSig) {
      sig = whitelistSig[0];
    } else {
      ecId = bestEcId;
    }

    console.log(quantity, sig, ecId);

    await geneticatsContract
      .whiteListBuySignature(quantity, sig, ecId, { value: payingAmount })
      .then((tx) => {
        setShowCheckout(false);
        setApproveInProgress(false);
        setTxInProgress(true);
        console.log(`${config.ETHERSCAN_TX}${tx.hash}`);
        setTxEtherScan(`${config.ETHERSCAN_TX}${tx.hash}`);
        return tx.wait().then((receipt) => {
          // console.log("txReceipt: ", receipt);
          if (receipt && receipt.status === 1) {
            toast.success("Successfully Bought NFT");
            setTab(1);
            setTxEtherScan(null);
          } else {
            toast.error("Transaction Failed");
            setTxInProgress(false);
            setTxEtherScan(null);
          }
        });
      })
      .catch(handleError);

    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const mintSale = async (quantity, totalPrice) => {
    setApproveInProgress(true);
    console.log(quantity, totalPrice);
    console.log("start mint");

    const payingAmount = ethers.utils.parseEther(totalPrice.toString());

    await geneticatsContract
      .buyCard(quantity, { value: payingAmount })
      .then((tx) => {
        setShowCheckout(false);
        setApproveInProgress(false);
        setTxInProgress(true);
        console.log(`${config.ETHERSCAN_TX}${tx.hash}`);
        setTxEtherScan(`${config.ETHERSCAN_TX}${tx.hash}`);
        return tx.wait().then((receipt) => {
          if (receipt && receipt.status === 1) {
            toast.success("Successfully Bought NFT");
            setTab(1);
            setTxEtherScan(null);
          } else {
            toast.error("Transaction Failed");
            setTxInProgress(false);
            setTxEtherScan(null);
          }
        });
      })
      .catch(handleError);

    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const handleError = (e) => {
    console.error(e);
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

  const onRedeemSuccess = (tokenId) => {
    setShowRedeem(null);
    setRedeemedId(tokenId);
  };

  return (
    <>
      <Header />
      <Box sx={sx.root} id="galeryContainer">
        <Box sx={sx.banner}>
          <Box sx={sx.largeBanner}>
            <img src={banner} style={{ width: "100%" }} alt="banner" />
          </Box>
          <Box sx={sx.smallBanner}>
            <img
              src={smallBanner}
              sx={sx.smallBanner}
              style={{ width: "100%" }}
              alt="banner"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#FAF9F5",
            }}
          >
            <Box sx={sx.nameContainer}>
              <Box sx={sx.name}>
                <Typography
                  style={{
                    color: "#FFF",
                    fontFamily: "poppins-semibold",
                    fontSize: "38px",
                  }}
                  align="center"
                >
                  GTCA
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={sx.container}>
          <div
            className="border"
            style={{ position: "relative", width: "100%" }}
          >
            <TopBorders />
            <Box sx={sx.infoContainer}>
              <Typography
                align="center"
                style={{
                  color: "#000407",
                  fontSize: "12px",
                  fontFamily: "poppins",
                }}
              >
                PROJECT NAME
              </Typography>
              <Typography align="center" variant="projectSubtitle">
                GENETICATS
              </Typography>
              <Box sx={sx.socialContainer}>
                <SocialButton
                  variant="opensea"
                  style={{ width: "28px", height: "28px" }}
                />
                {/* <SocialButton
                  variant="discord"
                  style={{ width: "28px", height: "28px" }}
                /> */}
                <SocialButton
                  variant="twitter"
                  style={{ width: "28px", height: "28px" }}
                />
                <SocialButton
                  variant="web"
                  style={{ width: "28px", height: "28px" }}
                />
              </Box>
              <Box
                style={{
                  gap: "20px",
                  flexWrap: "wrap",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  style={{
                    lineHeight: "40px",
                    fontSize: "22px",
                    fontFamily: "poppins-semibold",
                  }}
                >
                  Blockchain platform powered by
                </Typography>
                <a href="https://galaxis.xyz/#/" target="_blank">
                  <img src={galaxis} style={{ maxWidth: "150px" }} />
                </a>
              </Box>
              <Box sx={sx.badgeContainer}>
                <InfoBox label="ITEMS" value="10,000" sx={sx.badgeLeft} />
                {minted > 0 && (
                  <InfoBox label="SOLD" value={minted} sx={sx.badgeCenter} />
                )}
                <InfoBox
                  label="PRICE"
                  value={salePrice}
                  eth
                  sx={sx.badgeRight}
                />
              </Box>

              <ButtonGroup
                sx={sx.tabContainer}
                variant="outlined"
                size="medium"
                aria-label="medium outlined button group"
              >
                <Button
                  sx={{
                    ...sx.tabBtns,
                    ...(tab == 0 && sx.tabSelected),
                  }}
                  onClick={() => setTab(0)}
                >
                  SALE INFO
                </Button>
                <Button
                  sx={{ ...sx.tabBtns, ...(tab == 1 && sx.tabSelected) }}
                  onClick={() => setTab(1)}
                >
                  COLLECTION WALLET
                </Button>
              </ButtonGroup>
            </Box>
          </div>

          {tab == 0 && (
            <>
              {!initDone && (
                <Box mt={10}>
                  <Spinner color="#681EAA" />
                </Box>
              )}
              {initDone && (
                <>
                  <div
                    id="presaleSection"
                    className="borderupcoming"
                    style={{ width: "100%" }}
                  >
                    <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
                      <SectionDividers />
                      <Typography align="center" sx={sx.title}>
                        Pre-Sale{" "}
                        {saleStatus == SALE_STATUS.UPCOMING_PRESALE && (
                          <Box component="span">Upcoming</Box>
                        )}
                        {saleStatus == SALE_STATUS.PRESALE && (
                          <Box sx={sx.titleLive} component="span">
                            Is Live
                          </Box>
                        )}
                        {saleStatus != SALE_STATUS.PRESALE &&
                          saleStatus != SALE_STATUS.UPCOMING_PRESALE && (
                            <Box component="span">Ended</Box>
                          )}
                      </Typography>
                      {saleStatus == SALE_STATUS.UPCOMING_PRESALE && (
                        <Counter
                          date={presaleStart}
                          onFinish={getInfo}
                          id="presale"
                        />
                      )}
                      {/* February 27 at 12 pm GMT - Ends: February 28, 12 pm GMT */}
                      <Box sx={sx.timeContainer}>
                        <Timer
                          time={
                            <>
                              27. 02. 12PM GMT <br />-<br /> 28. 02. 12PM GMT
                            </>
                          }
                          title="FOR WHITELISTED ADDRESSES AND ETHER CARDS OWNERS ONLY"
                        />
                      </Box>
                      {saleStatus == SALE_STATUS.PRESALE && (
                        <Box mt={2}>
                          <Button
                            sx={{ margin: "10px" }}
                            variant="outlined"
                            onClick={handleMint}
                            disabled={!address || isLoading}
                          >
                            BUY NFT
                          </Button>
                          {!address && (
                            <Button
                              sx={{ margin: "10px" }}
                              onClick={handleConnect}
                            >
                              CONNECT WALLET
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  </div>

                  <div
                    id="saleSection"
                    className="borderupcoming"
                    style={{ width: "100%" }}
                  >
                    <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
                      <SectionDividers />
                      <Typography align="center" sx={sx.title}>
                        Public Sale{" "}
                        {saleStatus != SALE_STATUS.UPCOMING_PRESALE &&
                          saleStatus != SALE_STATUS.SALE &&
                          saleStatus != SALE_STATUS.SALE_ENDED && (
                            <Box component="span">Upcoming</Box>
                          )}
                        {saleStatus == SALE_STATUS.SALE && (
                          <Box sx={sx.titleLive} component="span">
                            Is Live
                          </Box>
                        )}
                        {saleStatus == SALE_STATUS.SALE_ENDED && (
                          <Box component="span">Ended</Box>
                        )}
                      </Typography>
                      {saleStatus != SALE_STATUS.UPCOMING_PRESALE &&
                        saleStatus != SALE_STATUS.SALE &&
                        saleStatus != SALE_STATUS.SALE_ENDED && (
                          <Counter
                            date={saleStart}
                            onFinish={getInfo}
                            id="sale"
                          />
                        )}
                      {/* February 28, 12 pm GMT - Ends: March 7, 12 pm GMT */}
                      <Box sx={sx.timeContainer}>
                        <Timer
                          time={
                            <>
                              28. 02. 12PM GMT <br />-<br /> 07. 03. 12PM GMT
                            </>
                          }
                          title="FOR ALL"
                        />
                      </Box>

                      {saleStatus == SALE_STATUS.SALE && (
                        <Box mt={2}>
                          <Button
                            sx={{ margin: "10px" }}
                            variant="outlined"
                            onClick={handleMint}
                            disabled={!address || isLoading}
                          >
                            BUY NFT
                          </Button>
                          {!address && (
                            <Button
                              sx={{ margin: "10px" }}
                              onClick={handleConnect}
                            >
                              CONNECT WALLET
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  </div>
                </>
              )}

              <div className="borderupcoming" style={{ width: "100%" }}>
                <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
                  <SectionDividers />
                  <Typography align="center" sx={sx.title}>
                    About Project
                  </Typography>
                  <Typography sx={sx.description}>
                    GenomesDAO is a biotech DAO focused on the safe, private,
                    and auditable monetization of genomic data. We’re merging
                    the bleeding edge of encryption technology (AMD-SEV Vaults)
                    with the blockchain to create the world’s first
                    decentralized data brokering platform where users have
                    full-custody and control of their genomic information.
                  </Typography>
                  <Typography sx={sx.description}>
                    Each Geneticat NFT includes a whole-genome sequencing kit
                    and a genome mining vault (powered by AMD). Once we’ve
                    sequenced and vaulted your genome — you receive a 1:1
                    generative NFT based on a set of queries from your genome.
                  </Typography>
                </Box>
              </div>
              <div className="borderupcoming" style={{ width: "100%" }}>
                <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
                  <SectionDividers />
                  <Typography align="center" variant="projectSubtitle">
                    Utility Traits
                  </Typography>

                  <Typography sx={sx.description}>
                    Galaxis empowers your NFTs with the most dynamic features
                    and functions available. Trait holders may gain special
                    access, community benefits and so much more!
                  </Typography>
                  <Box sx={{ ...sx.cardContainer, ...getShrinkStyle(BP4) }}>
                    <UtilityCard
                      image={physical}
                      title="Redeemable"
                      text="Clinical grade human genome sequencing kit."
                    />
                  </Box>
                </Box>
              </div>
            </>
          )}

          {tab == 1 && (
            <Wallet
              showRedeem={setShowRedeem}
              redeemedId={redeemedId}
              setRedeemedId={setRedeemedId}
            ></Wallet>
          )}

          <BottomBorders />
        </Box>

        <PermissionErrorModal
          isOpen={showErrorPopup}
          setOpen={setShowErrorPopup}
        />

        <CheckoutModal
          isOpen={showCheckout}
          setOpen={() => {
            if (!txInProgress && !approveInProgress) {
              setShowCheckout(false);
            }
          }}
          saleStatus={saleStatus}
          whitelistLimit={whitelistLimit}
          salePrice={salePrice}
          presalePrice={presalePrice}
          mintSale={mintSale}
          mintPresale={mintPresale}
        />

        <BackdropModal isOpen={approveInProgress} />

        <TxProgressModal isOpen={txInProgress} txEtherScan={txEtherScan} />

        <RedeemModal
          isOpen={showRedeem}
          setOpen={() => {
            if (!approveInProgress) {
              setShowRedeem(null);
            }
          }}
          redeemKyc={(email, tokenId) => {
            redeemKyc(email, tokenId, () => onRedeemSuccess(tokenId));
          }}
          loading={loading}
        />
      </Box>
    </>
  );
};

export default Home;

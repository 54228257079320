import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import web from '../assets/images/social/web.svg';
import twitter from '../assets/images/social/twitter.svg';
import discord from '../assets/images/social/discord.svg';
import opensea from '../assets/images/social/opensea.svg';

const sx = {
  root: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const CONFIG = {
  web: {
    url: 'https://genomes.io/',
    image: web,
  },
  twitter: {
    url: 'https://twitter.com/GenomesDAO',
    image: twitter,
  },
  discord: {
    url: 'https://discord.com/invite/genomes',
    image: discord,
  },
  opensea: {
    url: 'https://opensea.io/collection/official-geneticats/',
    image: opensea
  }
};

const SocialButton = ({ variant, style }) => {
  const { url, image } = CONFIG[variant];
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Box sx={sx.root} style={{ ...style }}>
        <img src={image} style={{ width: '100%' }} alt={variant} />
      </Box>
    </a>
  );
};

SocialButton.propTypes = {
  variant: PropTypes.oneOf(['discord', 'twitter', 'web']).isRequired,
};

export default SocialButton;

import React, { useEffect, useState } from "react";
import "./Embed.css";
import Media from "../../components/EmbedCard/Media";
import TraitPopup from "../../components/EmbedCard/TraitPopup";

import { useGeneticatContract } from "../../hooks/useContract";

import { SpinnerCircularFixed as Spinner } from "spinners-react";

const Embed = (props) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [traits, setTraits] = useState(null);
  const [loading, setLoading] = useState(null);
  const [showError, setShowError] = useState(null);
  const [showRedeemableTrait, setShowRedeemableTrait] = useState(null);

  const contract = useGeneticatContract(false);

  const fetchMetadata = async (uri) => {
    const metadata = await fetch(uri)
      .then((res) => res.json())
      .catch((err) => console.error(err));
    if (metadata && metadata.code != "404") {
      setImageUrl(metadata.image);
      setTraits(metadata.traits);
      // HARDCODED REDEEMABLE TRAIT
      setShowRedeemableTrait(!metadata.redeemed);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    let id = props.match.params.tokenId
      ? parseInt(props.match.params.tokenId)
      : 0;
    if (contract) {
      getMetadata(id);
    }
  }, [props, contract]);

  const getMetadata = async (id) => {
    console.log("fetching");
    if (loading || imageUrl) return;
    setLoading(true);
    const tokenUri = await contract.tokenURI(id);
    await fetchMetadata(tokenUri);
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <div className="e-spinner-container">
          <Spinner
            color="#681EAA"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          ></Spinner>
        </div>
      ) : showError ? (
        <div className="error-container">
          <div className="text">
            <span className="text-exclamation">!</span>
            <br></br>Invalid Metadata
          </div>
        </div>
      ) : (
        <>
          <Media
            mediaType={"image"}
            mediaUrl={imageUrl}
            traitPopup={<>{showRedeemableTrait && <TraitPopup></TraitPopup>}</>}
            customWidth={1} //default 1.365
          />
        </>
      )}
    </>
  );
};

export default Embed;

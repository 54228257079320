import React, { useState, useEffect, useRef } from "react";
import "./TraitPopup.css";
import closeIcon from "../../assets/images/closeIcon.svg";
import redeemableIcon from "../../assets/images/redeemableIcon.png";
import useContainerDimensions from "../../hooks/useContainerDimensions";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

const TraitPopup = ({ redeemable, showRedeem }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showRedeemBtn, setShowRedeemBtn] = useState(false);
  const containerRef = useRef();
  const { width } = useContainerDimensions(containerRef);
  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname && location.pathname == "/") {
      setShowRedeemBtn(true);
    } else {
      setShowRedeemBtn(false);
    }
  }, [location]);

  return (
    <div className={`trait-popup ${showPopup ? "details-on" : ""}`}>
      <a
        className={`trait-btn btn ${showPopup ? "details-on" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowPopup(true);
        }}
      >
        <img src={redeemableIcon}></img>
      </a>
      <div
        ref={containerRef}
        className={`details-slide text-center ${!redeemable && "redeemed"}`}
      >
        <div
          className="close-btn"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowPopup(false);
          }}
        >
          <img src={closeIcon} alt="close" />
        </div>
        <div
          className={`details-content`}
          style={{
            ...(width > 350 && { justifyContent: "space-evenly" }),
            ...(width > 500 && { justifyContent: "center" }),
          }}
        >
          <img
            src={redeemableIcon}
            style={{
              background: "black",
              maxWidth: "80px",
              maxHeight: "80px",
              padding: "10px",
              marginBottom: "10px",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "10px",
              width: "20%",
            }}
          ></img>
          <h3 className="mt-0 mb-0">Redeemable</h3>
          <p
            style={{
              ...(width < 350 && { fontSize: "0.7em" }),
            }}
          >
            This trait enables you to claim a Whole Genome Sequencing kit, a
            Genome Mining Vault and a lifetime subscription to the services at{" "}
            <a href="https://genomes.io" target="_blank">
              Genomes.io
            </a>
          </p>

          {showRedeemBtn && (
            <Button sx={{ mx: "auto" }} onClick={() => showRedeem()}>
              Redeem
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TraitPopup;

import { Box } from "@mui/material";

import leftTopTrick from "../assets/images/leftTopTrick.svg";
import rightTopTrick from "../assets/images/rightTopTrick.svg";

const TopBorders = () => {
  return (
    <Box style={{ width:"100%", display: "flex", justifyContent: "space-between" }}>
      <img src={leftTopTrick} style={{ maxHeight: "50px" }} />
      <img src={rightTopTrick} style={{ maxHeight: "50px" }} />
    </Box>
  );
};
export default TopBorders;

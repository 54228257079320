import "./App.css";
import React from "react";
import routes from "./routes";
import Web3Manager from "./components/Web3Manager/Web3Manager";
import { Box, ThemeProvider } from "@mui/material";
import { Route, Switch, HashRouter } from "react-router-dom";
import theme from "./theme";
import { ToastContainer } from "react-toast";

const sx = {
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#FFF",
  },
};
const App = () => (
  <ThemeProvider theme={theme}>
    <HashRouter basename="/">
      <Web3Manager>
        <Box sx={sx.root}>
          <Switch>
            {routes.map((route, key) => (
              <Route
                key={key}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
          </Switch>
        </Box>
      </Web3Manager>
    </HashRouter>
    <ToastContainer delay={4000} position="bottom-right" />
  </ThemeProvider>
);

export default App;

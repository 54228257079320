import { useCallback, useState } from "react"
import { ethers } from "ethers";
import axios from 'axios';
import md5 from "blueimp-md5";
import config from "../config";
import { toast } from "react-toast";
import useWeb3Ctx from "./useWeb3Ctx";

const redeemTrait = async (params) => {
    return axios({
        method: "post",
        url: `${config.API_URL}api/metadata/redeem`,
        data: params,
    })
};

const submitKycData = async (params) => {
    return axios({
        method: "post",
        url: `${config.API_URL}api/metadata/kyc`,
        data: params,
    }).then((res) => {
        return res.data;
    })
};

const handleApiError = (e) => {
    console.error(e);
    console.error("Error occured!")
    if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
    } else {
        toast.error(e.message)
    }
};

const handleWalletError = (e) => {
    if (e.error && e.error.message) {
        console.error(e.error.message);
        toast.error(e.error.message);
    } else if (e.message) {
        console.error(e.message);
        toast.error(e.message);
    }
};

const personalSign = async (message, address, ethersProvider) => {
    return ethersProvider
        .send("personal_sign", [message, address.toLowerCase()])
        .catch(handleWalletError);
};

const hashMessage = (kycHash, rowId, timestamp) => {
    let hexTimestamp = timestamp.toString(16).padStart(16, "0");
    let padRowId = rowId.toString(16).padStart(16, "0");
    const message = `${kycHash}${padRowId}${hexTimestamp}`;
    console.info("messageHash, ", message);
    return ethers.utils.keccak256(message.toString());
};

const hashKycData = (params) => {
    let message = JSON.stringify(params);
    return "0x" + md5(message);
};

const useRedeemKyc = () => {
    const { address, ethersProvider } = useWeb3Ctx();
    const [loading, setLoading] = useState(false)

    const redeemKyc = useCallback(async (email, tokenId, onSuccess) => {
        if (loading) return;
        setLoading(true);

        let params = {
            email: email,
            token_id: tokenId,
            owner_address: address
        }
        let formData = { email: email }

        const response = await submitKycData(params).catch(handleApiError);

        if (response) {
            const rowId = response.row;
            const timestamp = response.timestamp;
            const SECONDS_IN_DAY = 86400;
            // Add one day time limit for block to be mined
            const paddedTimestamp = Number(timestamp) + Number(SECONDS_IN_DAY);

            console.log(rowId);

            const kycHash = hashKycData({ ...formData });
            const messageHash = hashMessage(kycHash, rowId, paddedTimestamp);
            const signature = await personalSign(messageHash, address, ethersProvider);

            if (!signature) {
                setLoading(false);
                return;
            }
            //Recover the address from signature
            const recoveredAddress = ethers.utils.verifyMessage(
                ethers.utils.arrayify(messageHash),
                signature
            );
            if (address.toLowerCase() == recoveredAddress.toLowerCase()) {
                console.log("Signature is validated");
            } else {
                toast.error("Failed to validate signature");
                setLoading(false);
                return;
            }

            await redeemTrait({
                tokenId: tokenId,
                kycHash: kycHash,
                rowId: rowId,
                timestamp: paddedTimestamp,
                signature: signature,
            }).then(() => {
                toast.success("Successfully redeemed trait");
                if (onSuccess) {
                    onSuccess()
                }
            }).catch(handleApiError);
        }

        setLoading(false);

    }, [address, ethersProvider]);


    return { redeemKyc, loading }
};

export default useRedeemKyc;
import Onboard from "bnc-onboard";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SpinnerCircularFixed as Spinner } from "spinners-react";

import Web3Ctx from "../../context/Web3Ctx";
import config from "../../config";

const { DEPLOYED_NTW_NAME, DEPLOYED_CHAIN_ID, RPC_URL, FORTMATIC_KEY } = config;

const sx = {
  root: {
    display: "flex",
    minHeight: "100vh",
    height: "100%",
    weight: "100%",
    background: "#FFF",
  },
  container: {
    textAlign: "center",
    margin: "auto",
  },
};

const Web3Manager = ({ children }) => {
  const [onboard, setOnboard] = useState(null);
  const [address, setAddress] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [ethersProvider, setEthersProvider] = useState(null);
  const [defaultProvider, setDefaultProvider] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [networkName, setNetworkName] = useState(DEPLOYED_NTW_NAME);

  const [connecting, setConnecting] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const initApp = async () => {
      setInitDone(false);
      try {
        console.log("Initiating");
        const ob = Onboard({
          networkId: DEPLOYED_CHAIN_ID, // [Integer] The Ethereum network ID your Dapp uses.
          darkMode: false,
          blockPollingInterval: 12000,
          walletSelect: {
            wallets: [
              { walletName: "metamask" },
              { walletName: "coinbase" },
              { walletName: "trust", rpcUrl: RPC_URL },
              { walletName: "authereum" },
              { walletName: "wallet.io", rpcUrl: RPC_URL },
              { walletName: "atoken" },
              {
                walletName: "fortmatic",
                apiKey: FORTMATIC_KEY,
              },
              {
                walletName: "walletConnect",
                rpc: {
                  1: RPC_URL,
                  4: RPC_URL,
                },
              },
              { walletName: "opera" },
              { walletName: "operaTouch" },
              { walletName: "torus" },
              { walletName: "status" },
              { walletName: "walletLink", rpcUrl: RPC_URL },
              {
                walletName: "trezor",
                appUrl: "ether.cards",
                email: "info@ether.cards",
                rpcUrl: RPC_URL,
              },
              {
                walletName: "ledger",
                rpcUrl: RPC_URL,
              },
            ],
          },
          walletCheck: [
            { checkName: "derivationPath" },
            { checkName: "accounts" },
            { checkName: "connect" },
            { checkName: "network" },
          ],
          subscriptions: {
            wallet: (obWallet) => {
              // console.log(obWallet);
              if (obWallet.provider) {
                setWallet(obWallet);
                const provider = new ethers.providers.Web3Provider(
                  obWallet.provider,
                  "any"
                );
                setEthersProvider(provider);
                window.localStorage.setItem("selectedWallet", obWallet.name);
              } else {
                console.log("wallet not found, setting default provider");
                const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
                setEthersProvider(provider);
                setWallet(null);
              }
            },
            address: (obAddress) => {
              setAddress(obAddress);
              // console.log(obAddress);
            },
            network: (network) => {
              setChainId(network);
              // console.log(network);
            },
          },
        });

        console.log("init onboard done");

        setOnboard(ob);
        setInitDone(true);
      } catch (e) {
        console.log("onboard init error", e);
      }
    };

    if (location && location.pathname && location.pathname.includes("/embed")) {
      setInitDone(true);
    } else {
      initApp();
    }
    setDefaultProvider(new ethers.providers.JsonRpcProvider(RPC_URL));
  }, [location]);

  useEffect(() => {
    (async () => {
      const previouslySelectedWallet =
        window.localStorage.getItem("selectedWallet");

      if (previouslySelectedWallet && onboard) {
        await onboard.walletSelect(previouslySelectedWallet);
        await onboard.walletCheck();
      }
    })();
  }, [onboard]);

  useEffect(() => {
    if (ethersProvider) {
      subscribeNetwork(ethersProvider);
    }
  }, [ethersProvider]);

  useEffect(() => {
    if (address && isWalletConnected() == false) {
      console.log("set address null");
      setAddress(null);
    }
  }, [address]);

  const subscribeNetwork = async (provider) => {
    const network = await provider.getNetwork().catch((e) => {
      console.log("error:", e);
    });
    if (network) {
      setNetworkName(network.name);
      setChainId(network.chainId);
    }
  };

  const isWalletConnected = () => {
    console.log("check wallet state");
    if (onboard) {
      const state = onboard.getState();
      return state.wallet.name != null;
    } else return null;
  };

  const handleConnect = async (e) => {
    if (!initDone) return;
    if (onboard) {
      e.stopPropagation();
      console.log("reset");
      onboard.walletReset();
      console.log("walletSelect");
      const walletSelected = await onboard.walletSelect();
      if (walletSelected) {
        const walletReady = await onboard.walletCheck();
        console.log("walletReady", walletReady);
      }
      console.log("walletSelected", walletSelected);
    }
  };

  const handleDisconnect = () => {
    if (onboard) {
      console.log("logout wallet");
      onboard.walletReset();
      if (localStorage) {
        localStorage.removeItem("selectedWallet");
      }
    }
  };

  const walletCheck = () => {
    if (onboard) {
      onboard.walletCheck();
    }
  };

  const isCorrectNetwork = chainId === DEPLOYED_CHAIN_ID;

  if (!initDone) {
    return (
      <div style={sx.root}>
        <div style={sx.container}>
          <Spinner color="#681EAA" />
        </div>
      </div>
    );
  }

  return (
    <Web3Ctx.Provider
      value={{
        onboard,
        wallet,
        address,
        ethersProvider,
        defaultProvider,
        chainId,
        defaultChainId: config.DEPLOYED_CHAIN_ID,
        connecting,
        handleConnect,
        handleDisconnect,
        isCorrectNetwork,
        walletCheck,
      }}
    >
      {children}
    </Web3Ctx.Provider>
  );
};
export default Web3Manager;

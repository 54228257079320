import { Typography, Box } from "@mui/material";
import PopupModal from "../../../components/PopupModal";
import MintQuantity from "../../../components/MintQuantity";
import { SALE_STATUS } from "../Home";

const sx = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    fontFamily: "poppins",
    fontWeight: 700,
    size: 26,
    mb: 1,
  },
  text: {
    fontFamily: "poppins",
    fontSize: 12,
  },
};

const CheckoutModal = ({
  setOpen,
  isOpen,
  mintPresale,
  mintSale,
  saleStatus,
  presalePrice,
  salePrice,
  whitelistLimit,
}) => {
  const headerText =
    saleStatus == SALE_STATUS.PRESALE ? "Presale Checkout" : "Checkout";

  return (
    <PopupModal setOpen={setOpen} isOpen={isOpen} header={headerText}>
      <Box sx={sx.content}>
        <Typography sx={sx.title}>Geneticats Token</Typography>
        <Typography sx={sx.text}>
          Please select the number of NFT you wish to mint.
        </Typography>

        {saleStatus == SALE_STATUS.PRESALE && (
          <MintQuantity
            price={presalePrice}
            maxAmount={whitelistLimit}
            onClickMint={mintPresale}
          />
        )}
        {saleStatus == SALE_STATUS.SALE && (
          <MintQuantity
            price={salePrice}
            maxAmount={10}
            onClickMint={mintSale}
          />
        )}
      </Box>
    </PopupModal>
  );
};

export default CheckoutModal;

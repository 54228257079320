import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const sx = {
  root: {
    minHeight: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    px: 2,
  },
  time: {
    color: "#1C1E23",
    fontSize: "26px",
    lineHeight: "28px",
    fontFamily: "poppins",
  },
  title: {
    color: "##000407",
    fontSize: "12px",
    fontFamily: "poppins",
    lineHeight: "20px",
    mt: "5px",
  },
};
const Timer = ({ time, title }) => {
  return (
    <Box sx={sx.root}>
      <Typography sx={sx.time}>{time}</Typography>
      <Typography sx={sx.title}>{title}</Typography>
    </Box>
  );
};

Timer.propTypes = {
  time: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};
export default Timer;

import { Typography } from "@mui/material";
import PopupModal from "../../../components/PopupModal";

const HEADER = "We're Sorry";
const ERROR =
  "Your wallet does not have the permission to mint yet, but do not worry Public sale is coming soon!";

const PermissionErrorModal = ({ isOpen, setOpen }) => {
  return (
    <PopupModal isOpen={isOpen} setOpen={setOpen} header={HEADER}>
      <Typography>{ERROR}</Typography>
    </PopupModal>
  );
};

export default PermissionErrorModal;

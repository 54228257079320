import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const BP2 = '@media (max-width: 1148px)';
const BP3 = '@media (max-width: 599px)';
const BP4 = '@media (max-width: 899px)';
const BP5 = '@media (max-width: 779px)';

const sx = {
  root: {
    width: '100%',
    maxWidth: '350px',
    px: '27px',
    py: '27px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '18px',
    border: 'solid 1px #707070',
    '& img': {
      width: '41px',
      height: '41px',
    },
  },
  title: {
    pr: '6.6px',
    fontFamily: 'poppins-medium',
    minHeight: '55px',
    fontSize: '26px',
    color: '#030000',
    mt: '12px',
    textAlign: 'center',
  },
  text: {
    width: '100%',
    maxWidth: '330px',
    minHeight: '80px',
    maxHeight: '80px',
    fontFamily: 'bau',
    fontSize: '16px',
    lineHeight: '28px',
    textAlign: 'left',
    color: '#030000',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      width: '0.3em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#511BA3',
    },
    [BP3]: {
      textAlign: 'center',
      pr: '6.6px',
    },
  },
};

const UtilityCard = ({ title, text, image }) => {
  return (
    <Box sx={sx.root}>
      <Box style={{ paddingRight: '6.6px' }}>
        <img src={image} alt={title} />
      </Box>
      <Typography sx={sx.title}>{title}</Typography>
      <Typography sx={sx.text}>{text}</Typography>
    </Box>
  );
};

UtilityCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default UtilityCard;

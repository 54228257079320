import { SupportedChainId } from './chain'

export const ZOOM_2_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x7cdF091AF6a9ED75E3192500d3e5BB0f63e22Dea",
    [SupportedChainId.RINKEBY]: "0x491592F30D9a3d1887F486eA2A3c72ad82fAcF4D"
}

export const EC_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x97CA7FE0b0288f5EB85F386FeD876618FB9b8Ab8",
    [SupportedChainId.RINKEBY]: "0x8598b4d577824bdd04a42404ac2dfcfd8b0dab35"
}

export const GENETICATS_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x9e5d1c128294237da2ec39e237783a33db48c78b",
    [SupportedChainId.RINKEBY]: "0xCFBC219bA3fBaD98d0ab2cd328b15c0ea6CAECB3"
}

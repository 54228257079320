import Home from './views/home/Home';
import Embed from './views/embed/Embed';

const routes = [
     {
          'path': '/',
          'component': Home,
          'exact': true
     },
     {
          'path': '/embed/:tokenId',
          'component': Embed,
          'exact': true
     }
];

export default routes;


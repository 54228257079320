import useWeb3Ctx from "../hooks/useWeb3Ctx";
import { ECAddress } from "ec-commons";
import { Box, Button } from "@mui/material";

const BP5 = "@media (max-width: 500px)";

const sx = {
  connectBtn: {
    backgroundColor: "#681EAA",
    color: "#FAFAFA",
    fontSize: "14px",
    padding: "14px",
    fontFamily: "poppins-semibold",
    borderRadius: "8px",
    minWidth: "150px",
    "&:hover": {
      backgroundColor: "#681EAA",
    },
    "&:focus": {
      color: "#fff",
      opacity: 0.8,
    },
    "&:disabled": {
      backgroundColor: "primary.main",
      cursor: "not-allowed",
      opacity: 0.5,
    },
    "&:active": {
      outlineColor: "unset",
    },
  },
  disconnectBtn: {
    textAlign: "center",
    cursor: "pointer",
    color: "rgba(0,0,0,0.8)",
    transition: "color 0.3s ease-in-out",
    margin: 0,
    marginLeft: "-7px",
    fontSize: "0.8rem",
    fontWeight: "500",
    lineHeight: "0.3rem",
    marginTop: "-5px",
    "&:hover": {
      opacity: 0.8,
    },
  },
  address: {
    color: "#000",
    zIndex: 1,
    minWidth: "150px",
    [BP5]: {
      transform: "scale(0.9)",
    },
  },
  errorBtn: {
    fontFamily: "poppins-semibold",
    color: "#FAFAFA",
    fontSize: "14px",
    lineHeight: "1.75",
    fontWeight: "500",
    textTransform: "uppercase",
    padding: "14px",
    minWidth: "120px",
    backgroundColor: "#FF1414",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .3s",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#FF1414",
    },
  },
};

const ConnectWallet = () => {
  const {
    walletCheck,
    handleConnect,
    handleDisconnect,
    isCorrectNetwork,
    address,
  } = useWeb3Ctx();

  return (
    <>
      {!address && (
        <Button sx={sx.connectBtn} onClick={handleConnect}>
          Connect Wallet
        </Button>
      )}
      {address && isCorrectNetwork && (
        <Box sx={sx.address} onClick={(e) => e.stopPropagation()}>
          <ECAddress address={address} short blockie scale={5} />
          <Box sx={sx.disconnectBtn} onClick={() => handleDisconnect()}>
            disconnect
          </Box>
        </Box>
      )}
      {address && !isCorrectNetwork && (
        <Button sx={sx.errorBtn} onClick={walletCheck}>
          Wrong Network
        </Button>
      )}
    </>
  );
};

export default ConnectWallet;
